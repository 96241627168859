<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import { onMount } from 'svelte';

    export let show = false;
    export let username = '';
    let qrCodeDataUrl = '';

    $: if (show) {
        generateQRCode();
    }

    async function generateQRCode() {
        try {
            qrCodeDataUrl = await QRCode.toDataURL(window.location.href, {
                width: 250,
                margin: 2,
            });
        } catch (err) {
            console.error('Error generating QR code:', err);
        }
    }

    function downloadQRCode() {
        if (!qrCodeDataUrl) return;
        const link = document.createElement('a');
        link.download = `${username}-qr-code.png`;
        link.href = qrCodeDataUrl;
        link.click();
    }
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>{username} QR Code</Dialog.Title>
        </Dialog.Header>

        <div class="flex justify-center py-4">
            {#if qrCodeDataUrl}
                <img src="{qrCodeDataUrl}" alt="Profile QR Code" />
            {/if}
        </div>

        <div class="mt-4 flex justify-end">
            <Button on:click="{downloadQRCode}">Download</Button>
        </div>
    </Dialog.Content>
</Dialog.Root>
